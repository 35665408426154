.liveliness-check-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.liveliness-check-modal .modal-content {
  display: flex;
  border-radius: 8rem;
  background-color: #fff;
  width: 100%;
}
.liveliness-check-modal .modal-content .image-container {
  margin-right: 2rem;
}
.liveliness-check-modal .details-container h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.liveliness-check-modal .details-container table {
  width: 100%;
  margin-top: 1rem;
}
.liveliness-check-modal .details-container td {
  padding: 1.5rem;
}

.liveliness-modal {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=ComplianceLivelinessCheck.css.map */