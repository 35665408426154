.container-page-wrap {
	display: flex;
	flex: 1;
	padding: 2rem;
	// height: 95%;
	overflow: hidden;
	width: 100%;
	border-radius: 1.6rem;

	background-color: white;

	.container-page {
		height: 100%;
		width: 100%;
		background: #f7f7f7;
		border-radius: 1.6rem;
		// margin-top: 1.8rem;
		// flex: 1;
		position: relative;
		display: flex;
		align-items: flex-start;
		padding-top: 7.3rem;
		justify-content: center;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: bottom center;
		background-image: url('../../../../assets/images/graph_no_data.svg');

		.title-wrap {
			display: flex;
		}

		.container__top {
			width: 100%;
			height: 30%;
			position: absolute;
			top: 0;
			background-size: cover;
			right: 0;
			// z-index: 1000;

			figure {
				width: 100%;
				height: 100%;
				display: flex;

				svg {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.container__bottom {
			width: 100%;
			position: absolute;
			height: 70%;
			bottom: 0;
			right: 0;
			background: #ffffff;
			border-radius: 32px 32px 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 2rem;

			h5 {
				font-weight: 600;
				margin-top: 10rem;
				font-size: 2rem;
				text-align: center;
				margin-bottom: -1rem;
				// color: #000000;
			}

			p {
				font-weight: 400;
				font-size: 1.6rem;
				width: 46rem;
				text-align: center;
				color: #676767;
			}
		}

		.content {
			display: flex;
			max-height: 95%;
			border: 0.8px solid #e5e6e6;
			border-radius: 1.6rem;
			background-color: white;
			max-width: 45%;
			margin: 0 auto;
			z-index: 1;
			overflow: hidden;
		}
		.container__text {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 50%;
			gap: 0.2rem;

			h5 {
				font-weight: 600;
				font-size: 2rem;
				text-align: center;
				color: #014345;
			}

			p {
				font-weight: 400;
				font-size: 1.6rem;
				text-align: center;
				text-align: center;
				color: var(--base-background-black);
			}
		}
	}
}

.container-page-mode {
	display: flex;
	flex-direction: column;
	flex: 1;

	&__top {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 3.2rem;
		align-items: center;
		position: relative;
		z-index: 10;

		.top__left-wrap {
			display: flex;
			gap: 1rem;
			align-items: center;

			figure {
				display: grid;
				width: 4rem;
				height: 4rem;
				border-radius: 7.2rem;
				background: #014345;
				border: 1px solid #f7f8f7;
				place-items: center;
				cursor: pointer;
				box-shadow: -3px 7px 21px rgba(229, 229, 229, 0.37);

				svg {
					width: 40%;
					height: 40%;
					display: grid;
					place-items: center;
					transform: rotate(90deg);

					path {
						stroke: white;
					}
				}
			}

			span {
				display: flex;
				flex-direction: column;

				h5 {
					font-weight: 700;
					font-size: 2rem;
					color: #000000;
				}

				p {
					font-weight: 400;
					font-size: 1.4rem;
					color: #676767;
				}
			}
		}

		.top-right-wrap {
			width: max-content;
			cursor: pointer;

			.single-user-drop-drown-icon-wrap {
				position: relative;

				.icon-wrap {
					cursor: pointer;
					border-width: 0.2rem;
					border-radius: 50%;

					.img-box {
						width: 5rem;
						height: 5rem;
						border-radius: 50%;
						display: grid;
						place-items: center;

						.img {
							width: 65%;
							height: 65%;
							object-fit: contain;
						}
					}
				}

				.drop-wrap-box {
					position: absolute;
					top: 110%;
					right: 5%;
					height: max-content;
					width: max-content;
					border-width: 0.15rem;
					border-radius: 1.2rem;
					animation: moveDownSettingDrop 0.5s ease-in-out backwards;
					display: flex;
					flex-direction: column;
					padding: 2rem;
					gap: 2rem;

					.item-row {
						display: flex;
						align-items: center;
						gap: 1rem;

						.name {
							font-size: 1.3rem;
						}

						.icon-box {
							.img-box {
								width: 3.5rem;
								height: 3.5rem;
								border-radius: 50%;
								display: grid;
								place-items: center;

								.img {
									width: 50%;
									height: 50%;
									object-fit: contain;
								}
							}
						}
					}
				}
			}

			.process-settlement-btn {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				padding: 1.5rem;
				border-width: 0.15rem;
				border-radius: 1.2rem;

				.text {
					color: #014345;
					font-weight: 600;
					font-size: 1.55rem;
				}

				.icon-box {
					.img-box {
						width: 2rem;
						height: 2rem;

						.img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		max-height: 75rem;
	}
}

.transfer-mode {
	.container-page-wrap {
		padding: 0;
		flex-direction: column;
		overflow: hidden;
	}
	.container-page {
		background: white;
		background-image: none;
		overflow-y: auto;
		padding: 0;

		.atlas-payment-container {
			padding: 0;
			margin-top: 2rem;
			padding-top: 2rem;
			overflow-x: hidden;
			max-width: 30%;
			min-width: 44rem;

			&.expanded {
				max-width: 60%;
			}

			.form {
				padding: 0 !important;
			}
		}
		.content {
			border: none;
			max-height: 100%;
			max-width: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			overflow-y: auto;
			overflow-x: hidden;
			padding-bottom: 2rem;
		}

		.container__top {
			background-image: none !important;
		}

		.step2 {
			.preview-transfer {
				border: none;
			}
		}
	}
	.container-page-header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		border-bottom: 1px solid #eee;
		padding: 0 3rem;
		min-height: 6rem;

		.container-page-header__back-btn {
			display: flex;
			gap: 1rem;
			align-items: center;
			cursor: pointer;

			&:hover {
				figure {
					transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
					transform: translateX(-3px);
				}
			}

			figure {
				width: 1.5rem;
				height: 1.5rem;
				display: flex;
				place-items: center;
				transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

				svg {
					width: 100%;
					transform: rotate(180deg);
					height: 100%;
				}
			}
		}

		.container-page-header__steppers {
			display: flex;
			gap: 2rem;
			align-items: center;

			.step {
				display: flex;
				align-items: center;
				gap: 0.9rem;

				&.active {
					p {
						color: var(--primary-darkgreen-100, #014345);
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 600;
					}
				}

				span {
					&.active {
						background-color: #014345;

						p {
							color: white;
						}
					}
				}

				span {
					width: 30px;
					height: 30px;
					flex-shrink: 0;
					background-color: #e3e3e360;
					display: grid;
					place-items: center;
					border-radius: 50%;
					p {
						color: #162d4c;
						text-align: center;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 600;
					}
				}

				p {
					color: var(--raven-black-600, #646464);
					text-align: center;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
				}
			}
		}
	}

	.container-page-footer {
		border-top: 1px solid var(--raven-black-100, #eee);
		width: 100%;
		padding: 1rem 0;
		display: grid;
		place-items: center;

		.container-page-footer-btn {
			max-width: 30%;
			width: 100%;
			min-width: 44rem;
		}
	}
}
.container-wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

@keyframes moveDownSettingDrop {
	0% {
		transform: translateY(-5%);
		opacity: 1;
		// visibility: hidden;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
		// visibility: ;
	}
}
