.com-layout-index-wrap-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: calc(100vh - 8rem);
  overflow-y: scroll;
}
.com-layout-index-wrap-box .com__title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.com-layout-index-wrap-box .com__title .com__main {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.com-layout-index-wrap-box .com__title .com__main h5 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.7rem;
}
.com-layout-index-wrap-box .com__title .com__main p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  color: #676767;
}
.com-layout-index-wrap-box .com__title .title__swap-btn {
  display: flex;
  align-items: center;
  padding: 1rem 2.3rem;
  gap: 1rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  transition: transform 0.2s linear;
}
.com-layout-index-wrap-box .com__title .title__swap-btn:hover {
  transform: translateY(-3px);
}
.com-layout-index-wrap-box .com__title .title__swap-btn:hover figure {
  animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.com-layout-index-wrap-box .com__title .title__swap-btn p {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #014345;
}
.com-layout-index-wrap-box .com__title .title__swap-btn figure {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.com-layout-index-wrap-box .com__title .title__swap-btn figure svg {
  width: 100%;
  height: 100%;
}
.com-layout-index-wrap-box .children-route-wrapper-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 1.2rem;
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  padding: 2rem;
  gap: 1.5rem;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
  border-width: 0.15rem;
  min-height: 7rem;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .right-box-btn {
  margin-left: auto;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row {
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.5rem;
  background-color: rgb(1, 67, 69);
  border-radius: 2rem;
  transform: translateY(1.5rem);
  visibility: hidden;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row .name {
  transition: all 0.3s ease-in;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row:hover .name {
  font-weight: 600;
  color: rgb(1, 67, 69);
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row-active {
  background-color: rgb(232, 255, 246);
  cursor: default;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row-active::after {
  visibility: visible;
}
.com-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row-active .name {
  font-weight: 600;
  color: rgb(1, 67, 69);
}
.com-layout-index-wrap-box .children-route-wrapper-box .children-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}/*# sourceMappingURL=Compliance.css.map */