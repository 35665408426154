.liveliness-check-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	// padding: 2rem;

	.modal-content {
		display: flex;
		// align-items: center;
		// justify-content: space-between;
		// padding: 2rem;
		border-radius: 8rem;
		background-color: #fff;
		width: 100%;

		.image-container {
			margin-right: 2rem;
		}
	}

	.details-container h2 {
		font-size: 1.5rem;
		margin-bottom: 2rem;
	}

	.details-container table {
		width: 100%;
		margin-top: 1rem;
		// border: 1px solid red;
	}

	.details-container td {
		padding: 1.5rem;
	}
}

.liveliness-modal {
	width: 100%;
	height: 100%;
}
